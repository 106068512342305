import * as React from "react"
import { graphql } from "gatsby";
import Hero from "../../modules/hero/hero";
import RenderModules from "../../lib/renders/renderModules";

const Industry = ({data, location, ...other}) => {
  const {hero, modules} = data?.datoCmsPosttypeIndustry || {};
  return (
    <div {...other}>
      {hero && hero[0] && <Hero data={hero[0]} location={location}/>}
      {modules && <RenderModules data={modules}/>}
    </div>
  )
}

export default Industry


export const industryQuery = graphql`
query IndustryQuery($id : String) {
  datoCmsPosttypeIndustry(originalId : {eq : $id}) {
    hero{
      ...HeroFragment
    }
    model{
      name
    }
    modules{
      __typename
      ...ModuleCTASideBySideFragment
      ...ModuleCTAOverlayFragment
      ...ModuleSliderNoTabFragment
      ...ModuleTilesFragment
    }
    seoMetaTags {
      ...GatsbyDatoCmsSeoMetaTags
    }
  }
}
`
